<kip-form-group-wrapper class="p-2" [formGroup]="form" [showCancel]="false" (submittedAndValidated)="save()">
    <h2 class="kip-tab__header">Student Trees</h2>
    <div class="alert alert-primary">
        <ol>
            <li>If you want to override the default counts for a student, you need to set all values for Activities,
                Easy,
                Medium and Hard.</li>
            <li>If the grade is not set, it will default to their current grade.</li>
        </ol>
    </div>
    <kip-form-control-region id="regionId" label="Student Region" [control]="regionId" />

    <div class="row mb-2">
        <div class="col-4 fw-bold">
            Tree/Grade
        </div>
        <div class="col-2 fw-bold">
            Activities<br />
            (L{{ subjectData.defaultLessonActivities }}H{{ subjectData.defaultHomeworkActivities }})
        </div>
        <div class="col-2 fw-bold">
            Easy<br />
            (L{{ subjectData.defaultLessonEasyQuestions }}H{{ subjectData.defaultHomeworkEasyQuestions }})
        </div>
        <div class="col-2 fw-bold">
            Medium<br />
            (L{{ subjectData.defaultLessonMediumQuestions }}H{{ subjectData.defaultHomeworkMediumQuestions }})
        </div>
        <div class="col-2 fw-bold">
            Hard<br />
            (L{{ subjectData.defaultLessonHardQuestions }}H{{ subjectData.defaultHomeworkHardQuestions }})
        </div>
    </div>
    @for (contact of subjects.controls; track contact; let index = $index) {
    <kip-student-subject [id]="index" [regionId]="regionId" [studentSubjectForm]="$any(contact)" />
    }
</kip-form-group-wrapper>