import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';

import { StudentSubject } from '../../../../models';

@Component({
    selector: 'kip-student-subject',
    templateUrl: './student-subject.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentSubjectComponent {

    readonly #fb = inject(FormBuilderTypeSafe);

    readonly activitiesMin = 1;
    readonly activitiesMax = 20;
    readonly questionsMin = 0;
    readonly questionsMax = 50;

    get treeId() {
        return this.studentSubjectForm.getSafe(x => x.treeId);
    }

    get gradeId() {
        return this.studentSubjectForm.getSafe(x => x.gradeId);
    }

    get lessonActivities() {
        return this.studentSubjectForm.getSafe(x => x.lessonActivities);
    }

    get lessonEasyQuestions() {
        return this.studentSubjectForm.getSafe(x => x.lessonEasyQuestions);
    }

    get lessonMediumQuestions() {
        return this.studentSubjectForm.getSafe(x => x.lessonMediumQuestions);
    }

    get lessonHardQuestions() {
        return this.studentSubjectForm.getSafe(x => x.lessonHardQuestions);
    }

    get homeworkActivities() {
        return this.studentSubjectForm.getSafe(x => x.homeworkActivities);
    }

    get homeworkEasyQuestions() {
        return this.studentSubjectForm.getSafe(x => x.homeworkEasyQuestions);
    }

    get homeworkMediumQuestions() {
        return this.studentSubjectForm.getSafe(x => x.homeworkMediumQuestions);
    }

    get homeworkHardQuestions() {
        return this.studentSubjectForm.getSafe(x => x.homeworkHardQuestions);
    }

    get subjectId() {
        return this.studentSubjectForm.getSafe(x => x.subjectId);
    }

    get subjectName() {
        return this.studentSubjectForm.getSafe(x => x.subjectName);
    }

    @Input({ required: true }) regionId!: FormControl<number | null>;
    @Input({ required: true }) id = 0;
    @Input({ required: true }) studentSubjectForm: FormGroupTypeSafe<StudentSubject>;

    constructor() {
        this.studentSubjectForm = StudentSubjectComponent.buildDefaultControls(this.#fb);
    }

    /*eslint-disable @typescript-eslint/unbound-method */
    static buildDefaultControls(fb: FormBuilderTypeSafe): FormGroupTypeSafe<StudentSubject> {
        return fb.group<StudentSubject>({
            subjectId: new FormControl<number>(0),
            subjectName: new FormControl<string>('', Validators.required),
            treeId: new FormControl<number | null>(null),
            gradeId: new FormControl<number | null>(null),
            lessonActivities: new FormControl<number | null>(null),
            lessonEasyQuestions: new FormControl<number | null>(null),
            lessonMediumQuestions: new FormControl<number | null>(null),
            lessonHardQuestions: new FormControl<number | null>(null),
            homeworkActivities: new FormControl<number | null>(null),
            homeworkEasyQuestions: new FormControl<number | null>(null),
            homeworkMediumQuestions: new FormControl<number | null>(null),
            homeworkHardQuestions: new FormControl<number | null>(null)
        });
    }
    /*eslint-enable @typescript-eslint/unbound-method */

}