<div class="row">
    <div class="col-12 fw-bold">
        {{ subjectName.value }}
    </div>
</div>
<div class="row">
    <div class="col-4">
        <kip-form-control-tree class="me-2" label="" [control]="treeId" [id]="'tree' + id" [regionId]="regionId"
            [subjectId]="subjectId.value" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="lessonActivities" [id]="'lessonActivities' + id"
            [max]="activitiesMax" [min]="activitiesMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="lessonEasyQuestions" [id]="'lessonEasyQuestions' + id"
            [max]="questionsMax" [min]="questionsMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="lessonMediumQuestions"
            [id]="'lessonMediumQuestions' + id" [max]="questionsMax" [min]="questionsMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="lessonHardQuestions" [id]="'lessonHardQuestions' + id"
            [max]="questionsMax" [min]="questionsMin" />
    </div>
</div>
<div class="row">
    <div class="col-4">
        <kip-form-control-grade class="me-2" label="" [control]="gradeId" [id]="'grade' + id" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="homeworkActivities" [id]="'homeworkActivities' + id"
            [max]="activitiesMax" [min]="activitiesMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="homeworkEasyQuestions"
            [id]="'homeworkEasyQuestions' + id" [max]="questionsMax" [min]="questionsMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="homeworkMediumQuestions"
            [id]="'homeworkMediumQuestions' + id" [max]="questionsMax" [min]="questionsMin" />
    </div>
    <div class="col-2">
        <kip-form-control-number class="me-2" label="" [control]="homeworkHardQuestions"
            [id]="'homeworkHardQuestions' + id" [max]="questionsMax" [min]="questionsMin" />
    </div>
</div>