/* eslint-disable no-restricted-imports */

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as Brand from '@fortawesome/free-brands-svg-icons';
import * as Duo from '@fortawesome/pro-duotone-svg-icons';
import * as Light from '@fortawesome/pro-light-svg-icons';
import * as Regular from '@fortawesome/pro-regular-svg-icons';
import * as Solid from '@fortawesome/pro-solid-svg-icons';

import { IconsList } from './icons-list';

export const Icons: IconsList = {
  rankings: {
    poor: Regular.faFrown,
    ok: Regular.faMeh,
    good: Regular.faSmile
  },
  tree: Regular.faFolderTree,
  copy: Light.faCopy,
  excel: Regular.faFileExcel,
  idleStatus: {
    active: Regular.faPencil,
    idle: Regular.faSnooze,
    activeLessonOver: Regular.faAlarmClock,
    idleLessonOver: Regular.faAlarmSnooze,
    idleLessonOverLogoutSoon: Regular.faAlarmExclamation,
    idleLessonOverLogoutNow: Regular.faSiren
  },
  arrowRightFromBracket: {
    regular: Regular.faArrowRightFromBracket
  },
  arrowUpRightFromSquare: {
    regular: Regular.faArrowUpRightFromSquare
  },
  partyHorn:
  {
    regular: Regular.faPartyHorn
  },
  triangleExclamation: {
    regular: Regular.faTriangleExclamation
  },
  brainBooster: Regular.faBrainCircuit,
  initialLessonPlanTest: Regular.faSquare1,
  history: Regular.faClockRotateLeft,
  diyRule: Regular.faHammer,
  eyes: {
    eye: Regular.faEye,
    eyeSlash: Regular.faEyeSlash
  },
  logonMethod: {
    facebook: Brand.faFacebookF,
    google: Brand.faGoogle,
    twitter: Brand.faTwitter
  },
  sortDesc: Regular.faSortDesc,
  sortAsc: Regular.faSortAsc,
  home: {
    blank: Regular.faHomeBlank,
    regular: Regular.faHome,
    solid: Solid.faHome
  },
  signOut: Regular.faSignOut,
  avatar: Regular.faSunglasses,
  charts: {
    pie: Regular.faChartPie,
    bar: Regular.faChartBar,
    line: Regular.faChartLine
  },
  edit: Regular.faEdit,
  act: Solid.faUserSecret,
  status: Regular.faListCheck,
  toggle:
  {
    on: Regular.faToggleOn,
    off: Regular.faToggleOff
  },
  lessonDelivery: {
    inCentre: Regular.faChalkboardTeacher,
    online: Light.faWebcam
  },
  deliveryMethod:
  {
    inCentre: Regular.faMapMarkerAlt,
    online: Regular.faWifi
  },
  subject: {
    maths: Duo.faCalculatorAlt,
    english: Duo.faBook,
    earlyReading: Duo.faCubes,
    reading: Regular.faBookOpen,
    comprehension: Regular.faBookOpen,
    other: Regular.faPresentation
  },
  leadEvent: {
    general: Regular.faCheckCircle,
    assessmentBooked: Regular.faClipboardCheck,
    assessmentCancelled: Regular.faBan,
    callLogged: Regular.faPhone,
    notificationSent: Regular.faPaperPlane,
    task: Regular.faClipboard,
    other: Regular.faCheckCircle
  },
  noteCategory: {
    correspondence: Regular.faEnvelope,
    enrolment: Regular.faUserPlus,
    finance: Regular.faFileInvoice,
    general: Regular.faNote,
    leads: Regular.faStar,
    lesson: Regular.faUserEdit,
    medical: Regular.faCommentMedical,
    pickup: Regular.faCarAlt,
    session: Regular.faUsers,
    warning: Regular.faCommentExclamation,
    assessment: Regular.faBallotCheck,
    pinned: Solid.faMapPin
  },
  keyboard: Solid.faKeyboard,
  handle: Regular.faArrowsUpDownLeftRight,
  activityAction: {
    replace: Regular.faRetweet,
    comment: Regular.faComment,
    copyToHomework: Regular.faHome,
    copyToLesson: Regular.faChalkboardTeacher,
    moveToHomework: Regular.faHome,
    moveToLesson: Regular.faChalkboardTeacher,
    preview: Regular.faEye,
    print: Regular.faPrint,
    remove: Regular.faTrashAlt,
    replaceNext: Regular.faChevronRight,
    replacePrevious: Regular.faChevronLeft,
    review: Regular.faGlasses
  },
  activityAssociation: {
    initial: Regular.faArrowToTop,
    linked: Regular.faLink,
    manual: Regular.faPlusCircle,
    repeat: Regular.faRepeat,
    skillBuilder: Regular.faDumbbell,
    subsequent: Regular.faArrowToRight,
    arrowToLeft: Regular.faArrowToLeft,
    subsequentAccelerated: Regular.faArrowToRight,
    subsequentCheck: Regular.faQuestion,
    decision: Regular.faBan,
    inProgress: Regular.faChalkboard,
    unit: Regular.faLayerGroup,
    subsequentError: Regular.faQuestion,
    diy: Regular.faHammer
  },
  save: Light.faSave,
  success: Solid.faCheckCircle,
  reschedule: Regular.faCalendarEdit,
  increment: Regular.faPlusCircle,
  decrement: Regular.faMinusCircle,
  email: {
    regular: Regular.faEnvelope,
    solid: Solid.faEnvelope
  },
  booking: Regular.faBookOpen,
  admin: Regular.faKey,
  phone: Solid.faPhoneAlt,
  phoneSlash: Solid.faPhoneSlash,
  bracketsCurly: Regular.faBracketsCurly,
  accountBundles: Regular.faBoxUsd,
  enrolments: Regular.faUserFriends,
  emoji: Regular.faSmile,
  payments: Regular.faDollarSign,
  renew: Regular.faHandHoldingSeedling,
  splitPayment: Regular.faHandsUsd,
  terminate: Regular.faBan,
  adjustBundle: Regular.faHandScissors,
  addStudent: Regular.faUserPlus,
  delete: Regular.faTrashAlt,
  circle: Solid.faCircle,
  recording: {
    video: Light.faCircleVideo,
    text: Regular.faCircleEnvelope,
    audio: Regular.faCircleMicrophone,
    screen: Regular.faCircleCamera
  },
  folder: {
    open: Regular.faFolderOpen,
    closed: Regular.faFolder
  },
  activityTab: Regular.faStar,
  thumbtack: Solid.faThumbtack,
  replay: Regular.faRepeat,
  qrCode: Regular.faQrcode,
  hourglass: Regular.faHourglass,
  timer: Regular.faTimer,
  percent: Regular.faPercent,
  studentWithCap: Regular.faUserGraduate,
  leadDetailsTab: Regular.faBullseyeArrow,
  goals: {
    regular: Regular.faBullseyeArrow,
    solid: Solid.faBullseyeArrow
  },
  userSettings: Regular.faUserCircle,
  address: Regular.faMapMarker,
  enrolmentTab: Regular.faStar,
  sendActivity: Solid.faShare,
  sendWhiteboard: {
    regular: Regular.faShareSquare,
    solid: Solid.faShareSquare
  },
  introduction: Regular.faInfoCircle,
  workedSolution: Regular.faQuestionCircle,
  studentQuestion: Regular.faUserEdit,
  reportJira: {
    light: Light.faCommentAltExclamation,
    regular: Regular.faCommentAltExclamation
  },
  hasWhiteboard: Regular.faFileEdit,
  whiteboardOpen: Regular.faPencil,
  rename: Regular.faPencil,
  menu: Regular.faBars,
  mark: Solid.faCheck,
  whiteboardGrid: {
    blank: Regular.faRectangleLandscape,
    small: Regular.faTh,
    large: Regular.faThLarge,
    horizontal: Regular.faBars
  },
  whiteboardToolbar: {
    circle: Regular.faCircle,
    rectangle: Regular.faRectangleLandscape,
    triangle: Regular.faTriangle,
    line: Regular.faHorizontalRule,
    clock: Regular.faClock
  },
  task: {
    light: Light.faClipboardCheck,
    regular: Regular.faClipboardCheck
  },
  horizontalRule: Solid.faHorizontalRule,
  sendChat: Regular.faPaperPlane,
  clientError: Regular.faFrown,
  serverError: Regular.faServer,
  bundles: Regular.faClone,
  lightbulb: Regular.faLightbulb,
  bug: Regular.faBug,
  studentCurrentActivity: Regular.faUserCircle,
  studentDetailsTab: Regular.faUser,
  messages: Regular.faCommentAlt,
  dob: Regular.faBirthdayCake,
  grade: Regular.faGraduationCap,
  reload: Regular.faSpinner,
  events: Regular.faList,
  teach: Solid.faChalkboardTeacher,
  centre: Regular.faStoreAlt,
  expandAll: Regular.faSquareCaretDown,
  collapseAll: Regular.faSquareCaretUp,
  observe: Solid.faEye,
  dashboard: Regular.faTachometerAltFast,
  sessions: Regular.faChalkboardTeacher,
  deviceType: {
    apple: Brand.faApple,
    windows: Brand.faWindows,
    tablet: Regular.faTablet,
    desktop: Regular.faDesktop,
    laptop: Regular.faLaptop,
    phone: Regular.faMobile,
    display: Regular.faDisplay
  },
  deviceData: Regular.faComputerClassic,
  lessonPlan: Regular.faClipboard,
  calculator: Regular.faCalculator,
  enabled: Regular.faCheck,
  blocked: Regular.faBan,
  continueActivity: Regular.faPlay,
  audio: Solid.faPlay,
  explore: Regular.faMap,
  helpGuide: Regular.faInfoCircle,
  date: Regular.faCalendarDay,
  week: Regular.faCalendarWeek,
  settings: Regular.faCog,
  notifications: Regular.faBell,
  print: {
    regular: Regular.faPrint,
    solid: Solid.faPrint
  },
  download: Regular.faDownload,
  install: Regular.faMessageArrowUpRight,
  filter:
  {
    light: Light.faFilter,
    regular: Regular.faFilter,
    solid: Solid.faFilter
  },
  search: Regular.faSearch,
  fileContract: Regular.faFileContract,
  file: Regular.faFile,
  fileCheck: Regular.faFileCheck,
  upload: Regular.faUpload,
  uploadWork: Regular.faFileSignature,
  uploadCloud: Regular.faCloudUpload,
  identificationCard: Regular.faIdCard,
  information: {
    light: Light.faInfoCircle,
    regular: Regular.faInfoCircle,
    solid: Solid.faInfoCircle
  },
  due: Solid.faBellExclamation,
  warning: {
    regular: Regular.faExclamationTriangle,
    solid: Solid.faExclamationTriangle
  },
  alert: {
    light: Light.faExclamationCircle,
    regular: Regular.faExclamationCircle,
    solid: Solid.faExclamationCircle
  },
  help: {
    regular: Regular.faQuestionCircle,
    solid: Solid.faQuestionCircle
  },
  add: {
    light: Light.faPlus,
    regular: Regular.faPlus
  },
  activityList: Regular.faListAlt,
  numberedActivityList: Regular.faListOl,
  tutorAway: Regular.faCommentAltSmile,
  handRaised: {
    request: Regular.faHandPaper,
    acknowledged: Regular.faHandshake,
    notAcknowledged: Regular.faHandSparkles
  },
  messageDots: {
    regular: Regular.faMessageDots
  },
  scrolling: {
    active: Solid.faHandPaper,
    inactive: Regular.faHandPaper
  },
  payment: Regular.faCreditCard,
  presenting: Regular.faPresentation,
  uiVersionMismatch: Solid.faCloudExclamation,
  disconnected: Solid.faPlug,
  revert: Regular.faRetweet,
  random: Regular.faQuestion,
  undo: Regular.faUndo,
  sparkles: Regular.faSparkles,
  online: Regular.faWifi,
  inCentre: Regular.faLocationDot,
  lessonPlanReport: Regular.faQuestionCircle,
  lobby: {
    solid: Solid.faCouch,
    regular: Regular.faCouch
  },
  accepted: Regular.faCheck,
  svg: Regular.faFileImage,
  validity: {
    valid: Regular.faCheck,
    invalid: Regular.faTimes,
    warning: Regular.faExclamation
  },
  incomplete: {
    regular: Regular.faExclamationCircle
  },
  complete: {
    light: Light.faCheckCircle,
    regular: Regular.faCheckCircle
  },
  planned: Regular.faCheckCircle,
  unplanned: Regular.faTimesCircle,
  user:
  {
    light: Light.faUser,
    regular: Regular.faUser,
    solid: Solid.faUser
  },
  tutor: {
    solid: Solid.faUserAlt
  },
  unlock: {
    regular: Regular.faLock,
    solid: Solid.faLock
  },
  cease: Solid.faUserSlash,
  notStarted: Solid.faUserSlash,
  accounts: Regular.faHandsUsd,
  contacts: Regular.faUsers,
  takePhoto: {
    regular: Regular.faCamera,
    solid: Solid.faCamera
  },
  camera: {
    on: {
      solid: Solid.faVideo,
      regular: Regular.faVideo
    },
    off: {
      solid: Solid.faVideoSlash,
      regular: Regular.faVideoSlash
    }
  },
  share: {
    on: {
      solid: Solid.faAirplay,
      regular: Regular.faAirplay
    },
    off: {
      solid: Solid.faAirplay,
      regular: Regular.faAirplay
    }
  },
  browser: {
    safari: Brand.faSafari,
    chrome: Brand.faChrome,
    edge: Brand.faEdge,
    other: Regular.faBrowser
  },
  gender: {
    male: Regular.faMars,
    female: Regular.faVenus,
    unknown: Regular.faUser
  },
  microphone: {
    on: {
      solid: Solid.faMicrophone,
      regular: Regular.faMicrophone
    },
    off: {
      solid: Solid.faMicrophoneSlash,
      regular: Regular.faMicrophoneSlash
    }
  },
  playSpeed: {
    slow: Regular.faTurtle,
    normal: Regular.faTachometerAltAverage,
    fast: Regular.faRabbitFast
  },
  audioTest: Solid.faVolumeUp,
  audioPlayer: {
    slower: Regular.faTurtle,
    faster: Regular.faRabbitFast,
    play: Regular.faPlay,
    volume: Regular.faVolume,
    pause: Regular.faPause
  },
  radio: {
    on: Regular.faDotCircle,
    off: Regular.faCircle
  },
  note: {
    list: Regular.faFileAlt,
    empty: Regular.faFilePlus,
    existing: Solid.faCircleCheck,
    alt: Light.faMemo,
    square: Regular.faNote
  },
  quickEntry: Regular.faFileAlt,
  compress: Solid.faCompressAlt,
  expand: Solid.faExpandAlt,
  changeLog: Regular.faLaptopMedical,
  changes: Light.faFileAlt,
  follow: Regular.faShoePrints,
  moreActions: Regular.faEllipsisH,
  change: Regular.faRepeatAlt,
  stopwatch: {
    regular: Regular.faStopwatch,
    solid: Solid.faStopwatch
  },
  comment: Regular.faComment,
  fullScreen: {
    enter: Regular.faExpandWide,
    exit: Regular.faCompressWide
  },
  sound: {
    regular: Regular.faVolumeUp,
    solid: Solid.faVolumeUp
  },
  ellipsis: Solid.faEllipsisVAlt,
  checked: {
    regular: Regular.faCheckSquare,
    solid: Solid.faCheckSquare
  },
  unchecked: {
    regular: Regular.faSquare,
    solid: Solid.faSquare
  },
  close: {
    light: Light.faTimes,
    regular: Regular.faTimes,
    solid: Solid.faTimes
  },
  closePdf: Regular.faPencilAlt,
  grab: Solid.faGripDotsVertical,
  levelStart: Solid.faCircleArrowRight,
  levelEnd: Solid.faCircleMinus,
  preview: {
    regular: Regular.faEye,
    solid: Solid.faEye
  },
  video: Solid.faVideo,
  answers: Regular.faBallotCheck,
  answersSolid: Solid.faBook,
  time: {
    light: Light.faClock,
    regular: Regular.faClock
  },
  dyslexic: Regular.faLanguage,
  awards: Regular.faTrophy,
  whiteboardPage:
  {
    blank: Regular.faStickyNote,
    filled: Regular.faPencil,
    attempt: Regular.faPenField,
    additional: Regular.faFileSignature
  },
  points: {
    medal: Solid.faMedal,
    certificates: Regular.faAward,
    trophy: Solid.faTrophy,
    star: {
      regular: Regular.faStar,
      solid: Solid.faStar
    }
  },
  curriculum: Regular.faFolderTree,
  skillBuilder: Regular.faBullseye,
  assessment: Regular.faBallotCheck,
  addAssessment: Regular.faClipboardListCheck,
  whiteboard: Regular.faFileEdit,
  homework: Regular.faHome,
  homeworkSummary: Regular.faHouseUser,
  homeworkGood: Regular.faFileCheck,
  homeworkOk: Regular.faFileExclamation,
  homeworkBad: Regular.faFileTimes,
  lesson: Regular.faChalkboardTeacher,
  utility: Solid.faTools,
  markdownToolbar: {
    sup: Regular.faSuperscript,
    sub: Regular.faSubscript,
    bold: Regular.faBold,
    italic: Regular.faItalic,
    heading: Regular.faHeading,
    image: Regular.faFileImage,
    list: Regular.faList,
    listOl: Regular.faListOl,
    question: Regular.faQuestionCircle,
    imageWidth: Regular.faArrowsH,
    imageHeight: Regular.faArrowsV
  },
  activityType: {
    computer: Regular.faDesktop,
    manual: Regular.faFilePdf,
    adhoc: Regular.faScribble,
    unknown: Regular.faListOl
  },
  calendar: {
    attended: Solid.faCalendarCheck,
    cancelled: Solid.faBan,
    clipboard: Solid.faPaperclip,
    collapse: Regular.faMinusSquare,
    deferred: Regular.faHourglassClock,
    diy: Regular.faHammer,
    expand: Regular.faPlusSquare,
    flag: Solid.faFlag,
    general: Regular.faCalendar,
    holiday: Solid.faUmbrellaBeach,
    location: Solid.faLocationDot,
    main: Regular.faCalendarAlt,
    reschedule: Regular.faCalendarPen,
    student: Solid.faUser,
    students: Solid.faUsers
  },
  dueDate: Regular.faAlarmPlus,
  chat: Regular.faMessage,
  voiceChat: Regular.faMessageCaptions,
  child: {
    user: Solid.faChild,
    success: Solid.faChildReaching
  },
  portalMessages:
  {
    resetPassword: Regular.faLock,
    lessonAttend: Regular.faCheckCircle,
    lessonDefer: Regular.faTimesCircle,
    lessonDoItYourself: Regular.faHouseUser,
    chat: Regular.faComments,
    unknown: Regular.faChevronsRight,
    activityCompleted: Regular.faCheck,
    activityClosed: Regular.faEnvelope,
    activityOpened: Regular.faEnvelopeOpen,
    activityOpenRequested: Regular.faMousePointer,
    helpAcknowledged: Regular.faHandsHelping,
    helpCleared: Regular.faMinus,
    helpRequested: Regular.faHandPaper,
    lessonDisconnected: Regular.faMinus,
    lessonClosed: Regular.faMinus,
    lessonFocused: Regular.faChalkboardTeacher,
    lessonOpened: Regular.faPlus,
    lessonUnfocused: Regular.faSlash,
    lessonPlanUpdated: Regular.faClipboardList,
    lessonStartObserving: Regular.faEye,
    lessonStopObserving: Regular.faEyeSlash,
    lobbyClosed: Regular.faCouch,
    lobbyOpened: Regular.faCouch,
    onlineToggled: Regular.faMapMarkerAlt,
    sessionClosed: Regular.faMinus,
    sessionDisconnected: Regular.faMinus,
    sessionOpened: Regular.faPlus,
    skillBuilderAdded: Regular.faBullseyeArrow,
    startObserving: Regular.faEye,
    startTeaching: Regular.faChalkboardTeacher,
    stopObserving: Regular.faEyeSlash,
    stopTeaching: Regular.faMinus,
    stoppedInteracting: Regular.faExclamationCircle,
    startedInteracting: Regular.faCheckCircle,
    stoppedLookingTab: Regular.faBrowser,
    startedLookingTab: Regular.faChevronRight,
    studentAutoLoggedOutOnIdle: Regular.faSignOut,
    studentPublishDenied: Regular.faVideoSlash,
    studentPublishFailure: Regular.faVideoSlash,
    studentScreenSharePublishFailure: Regular.faEye,
    studentScreenSharePublishAllowed: Regular.faEye,
    studentScreenShareStreamDestroyed: Regular.faEye,
    studentSubscribeFailure: Regular.faVideoSlash,
    tutorAutoLoggedOutOnIdle: Regular.faSignOut,
    tutorPublishAllowed: Regular.faVideoSlash,
    tutorPublishDenied: Regular.faVideoSlash,
    tutorPublishFailure: Regular.faVideoSlash,
    tutorSubscribeFailure: Regular.faVideoSlash,
    tutorScreenSharePublishFailure: Regular.faEye,
    tutorScreenSharePublishAllowed: Regular.faEye,
    tutorScreenShareStreamDestroyed: Regular.faEye
  },
  angle: {
    left: {
      light: Light.faAngleLeft,
      regular: Regular.faAngleLeft
    },
    right: {
      light: Light.faAngleRight,
      regular: Regular.faAngleRight
    },
    down: {
      regular: Regular.faAngleDown
    },
    up: {
      regular: Regular.faAngleUp
    }
  },
  angles: {
    down: Regular.faAnglesDown
  },
  arrowsAltCircle: {
    right: Regular.faArrowAltCircleRight
  },
  arrows: {
    left:
    {
      regular: Regular.faArrowLeft,
      solid: Solid.faArrowLeft
    },
    right: {
      regular: Regular.faArrowRight,
      solid: Solid.faArrowRight
    },
    up: {
      regular: Regular.faArrowUp
    },
    down: {
      regular: Regular.faArrowDown
    }
  },
  external: Solid.faArrowUpRightFromSquare,
  carets: {
    left: Solid.faCaretLeft,
    right: Solid.faCaretRight,
    up: Solid.faCaretUp,
    down: Solid.faCaretDown
  },
  chevrons: {
    left: Regular.faChevronLeft,
    right: Regular.faChevronRight,
    up: Regular.faChevronUp,
    down: Regular.faChevronDown
  },
  view: {
    list: Regular.faTh,
    grid: Regular.faListUl
  },
  networkQuality: {
    excellent: Regular.faCheckCircle,
    acceptable: Regular.faCheckCircle,
    bad: Regular.faTimesCircle,
    unknown: Regular.faQuestionCircle
  },
  questions: {
    correct: Regular.faCheck,
    incorrectSingle: Regular.faMinus,
    incorrectMultiple: Regular.faTimes,
    skipped: Regular.faExclamationCircle
  },
  overlays: {
    diagramVenn: Regular.faDiagramVenn,
    fillDrip: Regular.faFillDrip
  },
  xMark: Regular.faXmark,
  penToSquare: Regular.faPenToSquare,
  refresh: Solid.faRotate,
  sync: Regular.faRotate,
  check: Regular.faCheck,
  circleExclamation: Regular.faCircleExclamation,
  circleInfo: {
    regular: Regular.faCircleInfo
  },
  circleCheck: Regular.faCircleCheck,
  dropIn: {
    add: Solid.faCirclePlus,
    icon: Regular.faArrowDownToLine
  }
};

export interface IconsListFlat {
  name: string;
  iconName: string;
  prefix: string;
  icon: IconDefinition;
}

export function enumerate(obj: any, returnedObjects: IconsListFlat[], prop: string) {
  if (!obj.iconName) {
    for (const prop2 in obj) {
      enumerate(obj[prop2], returnedObjects, `${prop}-${prop2}`);
    }
  } else {
    const icon = obj as IconDefinition;
    returnedObjects.push({ name: prop, iconName: icon.iconName, prefix: icon.prefix, icon: icon });
  }
}

export function enumerateIcons() {
  const returnedObjects: IconsListFlat[] = [];
  const iconsObject = Icons as { readonly [key: string]: any };
  for (const prop in iconsObject) {
    const obj = iconsObject[prop];
    enumerate(obj, returnedObjects, prop);
  }

  returnedObjects.sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);

  return returnedObjects;
}
